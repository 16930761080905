<template>
  <div
    class="max-w-[1440px] mx-auto sm:px-4 md:px-8 lg:px-10 xl:px-[84px] sm:py-10 pt-[64px] pb-[72px] flex flex-col sm:flex-row sm:gap-4 xl:gap-6"
  >
    <div class="relative shrink-0 hidden sm:block">
      <SideNavigation
        :show-menu="showMenu"
        :show-context-menu="showContextMenu"
        class="sticky left-0 top-[40px] max-h-screen overflow-y-auto pb-12"
      />
    </div>
    <div class="w-px hidden sm:block bg-primary_900 shrink-0" />
    <div class="px-4 w-full sm:px-0">
      <TabComponent
        v-if="route.name?.toString().includes('professors-challenge')"
        :tabs="challengePageTabs"
      />
      <slot />
    </div>
    <div class="w-px bg-primary_900 shrink-0 hidden xl:block" />
    <div
      class="flex order-first sm:order-last sm:hidden xl:block flex-col gap-[10px] w-full sm:max-w-[383px]"
    >
      <div v-if="!route.name?.toString().includes('partners-contact')">
        <div v-if="!route.name?.toString().includes('professors-challenge')">
          <UserProfileCard v-if="userId && showRegularBars && !commonStore.isMobile" />
          <GuestCard v-if="!userId" class="mb-4" />
          <TrendingUsers
            v-if="!route.name?.toString().includes('my-portal-id')"
            class="hidden sm:block mt-3"
          />
        </div>
        <div v-else>
          <UserChallengeHeader v-if="userId" />
          <GuestCard v-else />
          <div v-if="!commonStore.isMobile">
            <UsersPlacement
              v-if="route.path == '/professors-challenge/' || route.path == '/professors-challenge'"
              class="mt-[10px]"
            />
            <PredictionForm v-else-if="!userStore.user?.isCompetitionBlocked" place="sideNav" />
          </div>
        </div>
      </div>
      <div v-else class="xl:flex flex-col gap-4 hidden">
        <img class="w-[256px]" src="../public/logo.png" alt="picture of logo" />
        <a href="mailto:contact@underdogz.com">
          <p
            class="text-[16px] font-bold leading-[150%] tracking-[-0.32px] underline text-primary_500"
          >
            email: contact@underdogz.com
          </p>
        </a>
      </div>
    </div>
  </div>
  <ToasterNotification />
  <MobileNavigation v-if="showRegularBars && commonStore.isMobile" />
  <MobileHeaderBar v-if="showRegularBars && commonStore.isMobile" />
  <MobileHeaderAppBar v-if="!showRegularBars && commonStore.isMobile" />
  <ChallengesMobileNavigation v-if="commonStore.isMobile && route.path.includes('professors')" />
  <FAQDrawer />
</template>
<script setup lang="ts">
import { useUserStore } from '~/store/user';
import { getSocket } from '@/socket';
import { useCommonStore } from '~/store/commonStore';
import type { tabItem } from '~/interfaces';

const userStore = useUserStore();
const { t } = useI18n();
const commonStore = useCommonStore();
const router = useRouter();
const localePath = useLocalePath();
const userId = computed(() => userStore.user?.id);
const route = useRoute();
const regularRoutes = ['index', 'my-portal', 'betting-shops', 'users-search', 'challenge-landing'];
const contextMenuRoutes = ['professors'];
const showRegularBars = ref(true);
const showMenu = ref(true);
const showContextMenu = ref(true);

if (userId.value) {
  getSocket()?.emit('subscribe', `foruser_${userId.value}`);
}

const checkIfPageShouldHaveRegularBarsOrContextMenu = (routeName: string | undefined) => {
  // mora ovako da bi resetovao na svakoj ruti
  showRegularBars.value = false;
  showMenu.value = false;
  showContextMenu.value = false;
  regularRoutes.forEach((route) => {
    if (routeName?.includes(route) && !routeName.includes('my-portal-id')) {
      showRegularBars.value = true;
      showMenu.value = true;
    }
  });
  contextMenuRoutes.forEach((route) => {
    if (routeName?.includes(route)) {
      showContextMenu.value = true;
    }
  });
  if (!commonStore.isMobile && routeName?.includes('activities')) showMenu.value = true;
};
onMounted(() => {
  checkIfPageShouldHaveRegularBarsOrContextMenu(route.name?.toString());
});
watch(
  () => route.name,
  (newName) => {
    checkIfPageShouldHaveRegularBarsOrContextMenu(newName?.toString());
  }
);
const challengePageTabs = computed(
  () =>
    [
      {
        text: `${t('home')}`,
        action: () => {
          router.push(localePath('/professors-challenge/'));
        },
        active: route.path == '/professors-challenge' || route.path == '/professors-challenge/'
      },
      {
        text: `${t('placement')}`,
        action: () => {
          router.push(localePath('/professors-challenge/placements'));
        },
        active: route.path == '/professors-challenge/placements'
      },
      {
        text: `${t('predictions')}`,
        action: () => {
          router.push(localePath('/professors-challenge/predictions'));
        },
        active: route.path == '/professors-challenge/predictions',
        isHidden: !userId.value
      },
      {
        text: `${t('rules')}`,
        action: () => {
          router.push(localePath('/professors-challenge/rules'));
        },
        active: route.path == '/professors-challenge/rules'
      }
    ] as tabItem[]
);
</script>
